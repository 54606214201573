export class DomainUtil {
    static getSubDomain () {

        //PRODUCTION//////////////////////////////////
        return window.location.hostname.split(".")[0].replace('-staging', '');
        //PRODUCTION//////////////////////////////////

        //TESTING//////////////////////////////////

        // READY <<<===============
        // return 'ah';
        // return 'anmend';
        // return 'community';
        // return 'farmers';
        // return 'ffmc';
        // return 'mnps';
        // return 'mrh';
        // return 'musc';
        // return 'quorumhealth';
        // return 'riolindo';
        // return 'synccentral';
        // return 'tuftsmedicine';
        //TESTING//////////////////////////////////
    }

    static fetchFileForSubdomain(url) {
        return fetch(`${process.env.PUBLIC_URL}/schemas/${DomainUtil.getSubDomain()}/${url}`)
    }

    static getLogo() {
        return new Promise(async (resolve, reject) => {
            let svgPath = 'logo.svg';
            if(await DomainUtil.checkIfExistImage(svgPath)) {
                resolve(await DomainUtil.fetchImg(svgPath));
                return;
            }
            let pngPath = 'logo.png';
            if(await DomainUtil.checkIfExistImage(pngPath)) {
                resolve(await DomainUtil.fetchImg(pngPath));
                return;
            }

            resolve(''); // TODO resolve default image

        })
        
    }

    static checkIfExistImage(url) {
        //for testing
        //url = 'https://musc.synctalk.us/';
        //url = 'ah.synctalk.us';
        //url = 'https://riolindo.synctalk.us';
        return DomainUtil.fetchFileForSubdomain(url)
        .then(async (response) => {  
            if (response.ok && response.status.toString()[0] === '2') {
                const text = await response.text();
                if(text && !text.includes('<!DOCTYPE html>')) {
                    return true;
                }
              
            }
            return false;
          })
    }

    static fetchImg(url) {
        return new Promise((resolve) => {
            DomainUtil.fetchFileForSubdomain(url)
            .then(response => response.blob())
            .then(async (imageBlob) => {
                const reader = new FileReader();
                reader.readAsDataURL(imageBlob);
                reader.onloadend = () => {
                    resolve(reader.result);
                }
            })
            .catch((e) => {
                
                resolve();
            });
        })
         
    }

    static async fetchJsonFile(url) {
        try {
            const file = await DomainUtil.fetchFileForSubdomain(url).then((response) => response.text());
            return JSON.parse(file);
        } catch(e) {
            return {};
        }
    }

}