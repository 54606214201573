import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Welcome from "./pages/welcome/index.js";
import Account from "./pages/account/index.js";
import Core from "./pages/core/index.js";
/*import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";*/

function App() {

/*  Sentry.init({
    dsn: "https://7d55a140143049cdb6f7659689b7756f@o452213.ingest.sentry.io/6633866",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });*/

//Please keep
/*    var info = {
        timeOpened: new Date(),
        timezone: new Date().getTimezoneOffset() / 60,
        pageon: window.location.pathname,
        referrer: document.referrer,
        previousSites: window.history.length,
        browserName: window.navigator.appName,
        browserEngine: window.navigator.product,
        browserVersion1a: window.navigator.appVersion,
        browserVersion1b: navigator.userAgent,
        browserLanguage: navigator.language,
        browserOnline: navigator.onLine,
        browserPlatform: navigator.platform,
        javaEnabled: navigator.javaEnabled(),
        dataCookiesEnabled: navigator.cookieEnabled,
        dataCookies1: document.cookie,
        dataCookies2: decodeURIComponent(document.cookie.split(';')),
        dataStorage: localStorage,
        sizeScreenW: window.screen.width,
        sizeScreenH: window.screen.height,
        sizeDocW: window.document.width,
        sizeDocH: window.document.height,
        sizeInW: window.innerWidth,
        sizeInH: window.innerHeight,
        sizeAvailW: window.screen.availWidth,
        sizeAvailH: window.screen.availHeight,
        scrColorDepth: window.screen.colorDepth,
        scrPixelDepth: window.screen.pixelDepth,

    }*/
    // console.log(info)
  return (
    <Routes>
      <Route path="/pages/welcome" element={<Welcome />} />
      <Route path="/pages/account" element={<Account />} />
      <Route path="/pages/core" element={<Core />} />
      <Route path="/" element={<Navigate replace to="/pages/welcome" />} />
    </Routes>
  );
}

export default App;
