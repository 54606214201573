import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import styles from './index.module.scss';
import { DomainUtil } from '../../utils/domain.util';
import { FormUtil } from '../../utils/form.util';
import Loader from '../../components/Loader';

////////////////////////////Custom Widgets/////////////////////////////

function CustomSelect(props) {
  FormUtil.enableDissableGoNext(props);

  const onChange = (event) => {
    props.onChange(event.target.value);
    FormUtil.enableDissableGoNext(props);
  }
  
    return (
        <select
            id={props.id}
            name={props.id}
            key={props.id  + "_Inputkey"}
            value={props.value}
            required={props.required}
            autoFocus={props.autofocus}
            className="fs-anim-lower customSelect"
            onChange={onChange}
        >
         {props.options.enumOptions.map(enumItem => {
                return (<React.Fragment key={enumItem.value  + "_optionsKey"}>
                    <option value={enumItem.value}>
                        {enumItem.value}
                    </option>
                </React.Fragment>);
            })}
        </select>
    );
};

function CustomRadio(props) {
  FormUtil.enableDissableGoNext(props);

    return (<>
        {props.options.enumOptions.map(enumItem => {
            return (<React.Fragment key={enumItem.value  + "_Fragkey"}>
                <div className={`${styles.radiobtn} fs-anim-lower`}  key={props.id  + "_Inputkey"}>
                    <input
                        //This name must be unique throughout the entire form
                        //id must match the label's htmlfor
                        id={props.id + "_" + enumItem.value}
                        type="radio"
                        //This name must be the same for each radio in the group
                        name={props.id + "_group"}
                        key={props.id  + "_Inputkey"}
                        value={enumItem.value}
                        required={enumItem.required}
                        autoFocus={enumItem.autofocus}
                        checked={enumItem.checked}
                        className="fs-anim-lower"
                        onChange={(event) => props.onChange(event.target.value)}
                    />
                    <label htmlFor={props.id + "_" + enumItem.value} key={enumItem.id + "_labelkey"}>{enumItem.value}</label>
                </div>
            </React.Fragment>);
        })}
    </>);
};

function CustomDate(props) {
  FormUtil.enableDissableGoNext(props);

    return (<>
                    <input
                        id={props.value + "_id"}
                        type="date"
                        name={props.id + "_group"}
                        key={props.id  + "_Inputkey"}
                        value={props.value}
                        required={props.required}
                        autoFocus={props.autofocus}
                        className="fs-anim-lower"
                        onChange={(event) => props.onChange(event.target.value)}
                    />
    </>);
};

function CustomInput(props) {
  FormUtil.enableDissableGoNext(props);

    return (<>
        <input type="text"
               className="fs-anim-lower"
               id={props.id}
               name={props.id}
               key={props.id}
               value={props.value}
               placeholder={props.placeholder}
               required={props.required}
               autoFocus={props.autofocus}
               onChange={(event) => props.onChange(event.target.value)} />
    </>);
}

function CustomTextInput(props) {
    FormUtil.enableDissableGoNext(props);

    return (<>
        <textarea
               className="fs-anim-lower"
               id={props.id}
               name={props.id}
               key={props.id}
               value={props.value}
               placeholder={props.placeholder}
               required={props.required}
               autoFocus={props.autofocus}
               onChange={(event) => props.onChange(event.target.value)} />
    </>);
}

const widgets = {
    CustomInput: CustomInput,
    CustomSelect: CustomSelect,
    CustomRadio: CustomRadio,
    CustomDate: CustomDate,
    CustomTextInput: CustomTextInput
};

const customFormats = {
  'email': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
////////////////////////////End Custom Widgets///////////////////////////


////////////////////////////Custom Templates/////////////////////////////

function ObjectFieldTemplate(props) {
  const { properties } = props;

  return (
    <ol className="fs-fields">
      {properties.map((element, index) => {
        return (
          <li key={element.content.key}>
            {element.content}
          </li>
        )
      })}
    </ol>
  );
}

function CustomFieldTemplate(props) {
  // console.log('Custom Template ', `#${props.id}: `, props)

  const {id, label, help, required, children, rawDescription, errors} = props;
  
  if(id === "root") return (<>{children}</>)

  return (<>
    <label htmlFor={id} className="fs-field-label fs-anim-upper">{label}{required ? "*" : null}</label>
    <p id={`${id}__description`} className="field-description fs-anim-lower">{rawDescription}</p>
    {children}
    {errors}
    {help}
  </>);
}

////////////////////////////End Custom Templates/////////////////////////

const log = (type) => console.log.bind(console, type);

function validate(formData, errors) {
  // console.log('validate', formData)
  return errors;
}

////////////////////////////Primary Function/////////////////////////////

function Core() {

    const [formData, setFormData] = useState();

    const [schema, setSchema] = useState('');
    const [uiSchema, setUiSchema] = useState('');

    useEffect(() => {
        ////////////////////////////Load Scripts/////////////////////////////////

        DomainUtil.fetchJsonFile('mainSchema.json').then((sch) => setSchema(sch))
        DomainUtil.fetchJsonFile('uiSchema.json').then((uiSch) => setUiSchema(uiSch));

        return () => {
            const element = document.getElementsByClassName('fs-controls');
            element[0].remove();
        };
    }, []);

    const handleSubmit = (props) => {
      console.log('FORM SUBMIT: ', props.formData)

      const rawFormData = props.formData;
      const fieldsList = document.querySelector('ol.fs-fields');
      const fields = [].slice.call(fieldsList.children);
      const actualFieldsIds = fields.map((li) => {
        const fieldId = li.getElementsByTagName('label')[0].getAttribute('for').substring('root_'.length)
        return fieldId;
      })
      for (var key in rawFormData) {
        if (rawFormData.hasOwnProperty(key)) {
            if (!actualFieldsIds.includes(key)) delete rawFormData[key];
        }
      }

      console.log('FORM SUBMIT filtered actual data: ', rawFormData);
    }

    const formOnChange = (data) => {
      // console.log('formOnChange: ', data)
    };

    useEffect(() => {
        if(schema && uiSchema) {

          const formDataObject = Object.assign(...uiSchema["ui:order"].map(fieldName => ({ [fieldName]: '' })));
          setFormData(formDataObject);

            let scriptList = [
                '../../modernizr.custom.js',
                '../../classie.js',
                '../../selectFx.js',
                '../../fullscreenForm.js',
                '../../start.js'
            ];
            scriptList.forEach(function(a) {
                let script = document.createElement('script');
    
                script.src = a;
                script.type = "text/javascript";
                script.async = false;
                document.body.appendChild(script);
            });
        }
    }, [schema, uiSchema])
    if(!schema || !uiSchema) {
       return (<Loader></Loader>);
    }
    return (<>
        {formData && <Form schema={schema}
              ObjectFieldTemplate={ObjectFieldTemplate}
              FieldTemplate={CustomFieldTemplate}
              uiSchema={uiSchema}
              widgets={widgets}
              customFormats={customFormats}
              formData={formData}
              className={"fs-form fs-form-full"}
              onChange={formOnChange}
              onSubmit={handleSubmit}
              onError={log("errors")}
              liveValidate
              validate={validate}
              noHtml5Validate={true}
              showErrorList={false}
        >
            <button className="fs-submit" type="submit">Submit</button>
        </Form>
      }
    </>);
}

export default Core;