/*global gtag*/
import React from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import { ReactComponent as Greeting } from '../../assets/img/welcome.svg';
import styles from './index.module.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DynamicLogo from '../../components/DynamicLogo';



function Welcome() {
    let navigate = useNavigate();

    const search = useLocation().search;
    const tenant = new URLSearchParams(search).get('tenant');

    console.log('tenant:', tenant); // TODO map to logo list
      

    React.useEffect(() => {
      
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          navigate('/pages/account', { replace: true });
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }, [navigate]);


    const onLetsGetStartedClick = () => {
        gtag('event', 'GET_STARTED_BUTTON_CLICK');
    }



    return (
        <Container fluid className="d-flex flex-column min-vh-100 justify-content-center align-items-center" id={styles.container}>
            <Row className="">
                <Col className={styles.logo}>
                     <DynamicLogo></DynamicLogo>
                </Col>
            </Row>
            <Row>
                <Col className={styles.welcomeHeader} >
                    <svg><Greeting width="300px" height="199px"/></svg>
                </Col>
            </Row>
            <Row>
                <Col className={styles.welcomeText}>
                    We are so excited to have you participate and meet our specialists and Karla.
                </Col>
            </Row>
            <Row>
                <Col className={styles.getStartedButton}>
                    <Link to="/pages/account">
                        <Button className={styles.customButton} size="lg" onClick={onLetsGetStartedClick}>
                            Let's Get Started
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col className={styles.welcomeSubText}>
                    5 Minute Registration
                </Col>
            </Row>
        </Container>
    )
}

export default Welcome;