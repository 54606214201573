export class FormUtil {
 
  static enableDissableGoNext(props) {
    if(window.FFormInstance) {

      const currentLi = window.FFormInstance.fields[window.FFormInstance.current];
  
      if(currentLi && currentLi.querySelectorAll(`[for="${props.id}"]`).length > 0) {
        if (props && props.rawErrors && props.rawErrors.length) {
          window.FFormInstance._disableGoNext();
          window.FFormInstance._nextBtnEnabled(false);
          window.FFormInstance.passedSteps.delete(window.FFormInstance.current);
        }
        if (props && props.rawErrors && props.rawErrors.length === 0) {
          window.FFormInstance._enableGoNext();
          window.FFormInstance._nextBtnEnabled(true);
          window.FFormInstance.passedSteps.add(window.FFormInstance.current);
        }
      }
  
    }
  }

  static createNewUserRequest(data) {

    const options = {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': process.env.REACT_APP_AWS_API_KEY
        },
        method: 'POST',
        body: JSON.stringify(data)  
    };
    
    fetch(process.env.REACT_APP_AWS_API_URL, options )
      .then( response => {
        console.log('response', response)
        return response.json()
      } )
      .then( response => {
        console.log('API response: ', response)
      }).catch((err) => {
        // console.log('ERRORITA: ', err);
      });
  }

}