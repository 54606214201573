import React , { useState } from "react";
import { DomainUtil } from '../utils/domain.util';
import styles from './DynamicLogo.module.scss';

function LogoImage() {
    const [imageBlob, setImage] = useState('');
    React.useEffect(() => {
        DomainUtil.getLogo().then((res) => {
            setImage(res);
        })
  
      }, []);


    return (<img src={imageBlob} className={styles.logoImage} alt="Logo" />)
}


export default LogoImage;