/*global gtag*/
import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { FormUtil } from "../../utils/form.util";
import 'react-phone-number-input/style.css';


const schema = {
    "type": "object",
    "required": [
        "firstName",
        "lastName",
        "email",
        "telephone",
        "password",
        "confirmPassword"
    ],
    "properties": {
        "firstName": {            
            "type": "string",
            "title": "Hi, I'm Karla®. What's your first name?",
            "minLength": 1,
            "maxLength": 30
        },
        "lastName": {
            "type": "string",
            "title": "Nice to meet you. What's your last name?",
            "minLength": 1,
            "maxLength": 30
        },
        "email": {
            "type": "string",
            "format": 'email',
            "title": "What's your email address?",
            "minLength": 5,
            "maxLength": 50
        },
        "telephone": {
            "type": "string",
            "title": "Please submit your mobile phone number.",
            "minLength": 10,
            "maxLength": 15
        },
        "password": {
            "type": "string",
            "format": 'password',
            "title": "Please create a password for your account.",
            "minLength": 8,
            "maxLength": 20
        },
        "confirmPassword": {
          "type": "string",
          "format": 'password',
          "title": "Please confirm your password.",
            "minLength": 8,
            "maxLength": 20
      },
    }
}

const uiSchema = {
  "firstName": {
      "ui:autofocus": true,
      "ui:widget": InputCustomWidget
  },
  "lastName": {
      "ui:autofocus": true,
      "ui:widget": InputCustomWidget
  },
  "email": {
      "ui:autofocus": true,
      "ui:widget": InputCustomWidget
  },
  "telephone": {
      "ui:autofocus": true,
      "ui:widget": PhoneCustomWidget
  },
  "password": {
      "ui:autofocus": true,
      "ui:widget": PasswordCustomWidget
  },
  "confirmPassword": {
      "ui:autofocus": true,
      "ui:widget": PasswordCustomWidget
  }
}

function InputCustomWidget(props) {
  FormUtil.enableDissableGoNext(props);

  return (<>
    <input type="text"
            className="fs-anim-lower"
            id={props.id}
            name={props.id}
            key={props.id}
            value={props.value}
            required={props.required}
            autoFocus={props.autofocus}
            onChange={(event) => props.onChange(event.target.value)} />
  </>);
}

function PhoneCustomWidget(props) {

  const [showMessage, setShowMessage] = useState(false);

  const onChange = (value) => {
    setShowMessage(true);
    props.onChange(value);
  }

  if((props.value && isValidPhoneNumber(props.value))) {
    window.FFormInstance._enableGoNext();
  } else {
    if (window.FFormInstance) window.FFormInstance._disableGoNext();
  }
   
  return (<>
    <PhoneInput
      defaultCountry="US"
      className="fs-anim-lower"
      placeholder="Enter phone number"
      onChange={onChange}
      value={props.value}
    />
    {showMessage ? props.value && isValidPhoneNumber(props.value) ? null : <p className="text-danger">Please enter valid phone number</p> : null}
  </>)
}

function PasswordCustomWidget(props) {
  FormUtil.enableDissableGoNext(props);
  
  return (<>
    <input type="password"
            className="fs-anim-lower"
            id={props.id}
            name={props.id}
            key={props.id}
            value={props.value}
            required={props.required}
            onChange={(event) => props.onChange(event.target.value)} />
  </>);
}

const widgets = {
  InputCustomWidget: InputCustomWidget,
  PhoneCustomWidget: PhoneCustomWidget,
  PasswordCustomWidget: PasswordCustomWidget
}

const customFormats = {
  'email': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  'password': /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,20}$/
};


// ===================================================================================

function ObjectFieldTemplate(props) {
  const { properties } = props;

  return (
    <ol className="fs-fields">
      {properties.map((element, index) => {
          return (
            <li key={element.content.key}>
              {element.content}
            </li>
          )
        })}
    </ol>
  );
}

function CustomFieldTemplate(props) {
  // console.log('Custom Template ', `#${props.id}: `, props)

  const {id, label, help, required, children, description, errors} = props;// rawDescription, rawErrors, formData
  
  if(id === "root") return (<>{children}</>)

  return (<>
    <label htmlFor={id} className="fs-field-label fs-anim-upper">{label}{required ? "*" : null}</label>
    {/* <p className="fs-anim-lower">{rawDescription}</p> */}
    {description}
    {children}
    {errors}
    {help}
  </>);
}

// ===================================================================================


// Custom validation
function validate(formData, errors) {

  if (/(.)\1\1/.test(formData.password)) {
    errors.password.addError("May not contain more than 2 identical character in a row.");
  }

  if (formData.password && (formData.password.includes(formData.firstName) || formData.password.includes(formData.lastName))) {
    errors.password.addError("Password may not contain any part of your name or email address");
  }

  if(formData.password && formData.confirmPassword) {
    if (formData.password !== formData.confirmPassword && errors.confirmPassword) {
      errors.confirmPassword.addError("Password do not match.");
    }
  }
  
  if(formData.email) {
    const email = formData.email;
    var name   = email.substring(0, email.lastIndexOf("@"));
    var domain = email.substring(email.lastIndexOf("@") +1);
    var domainCleared = domain.substr(0, domain.indexOf('.')); 

    if (formData.password && (formData.password.includes(name) || formData.password.includes(domainCleared))) {
      errors.password.addError("Password may not contain any part of your name or email address");
    }
  }
 
  return errors;
}
function transformErrors(errors) {
  return errors.map(error => {
    if (error.property === ".password" && error.name === "format" ) {
      error.message = "At least 8 characters in length with a max of 20 characters. Must contains lower case, upper case, number and special character."
    }
    return error;
  });
}
//end Custom validation


function Account() {

    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      password: '',
      confirmPassword: ''
    });

    useEffect(() => {
      let scriptList = [
        '../../modernizr.custom.js',
        '../../classie.js',
        '../../selectFx.js',
        '../../fullscreenForm.js',
        '../../start.js'
      ];

      scriptList.forEach(function(a) {
          let script = document.createElement('script');

          script.src = a; 
          script.type = "text/javascript";
          script.async = false;
          document.body.appendChild(script);
      });

      return () => {
        const element = document.getElementsByClassName('fs-controls');
        element[0].remove();
      };
    }, []);

    const formOnChange = (props) => {
      setFormData({...props.formData});
    };

    const trackSubmitBtnClick = () => {
      if(validate(formData, []).length === 0) {
        gtag('event', 'SUBMIT_BUTTON_CLICK', {
          email: formData.email,
          phone: formData.phone,
          firstName: formData.firstName,
          lastName: formData.lastName
        });
      }
    }

    const formOnSubmit = (props) => {
      trackSubmitBtnClick();
      FormUtil.createNewUserRequest(props.formData);
    };

    

    const log = (type) => console.log.bind(console, type);

    return (
        <Form schema={schema} 
              uiSchema={uiSchema}
              widgets={widgets}
              customFormats={customFormats}
              ObjectFieldTemplate={ObjectFieldTemplate}
              FieldTemplate={CustomFieldTemplate}
              formData={formData}
              className={"fs-form fs-form-full"}
              onChange={formOnChange}
              onSubmit={formOnSubmit}
              onError={log("errors")} 
              transformErrors={transformErrors} 
              validate={validate}
              liveValidate
              noHtml5Validate={true}
              showErrorList={false}
        >
          <button className="fs-submit" type="submit">Submit</button>
        </Form>
    );
}
export default Account;


